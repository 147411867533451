import { CheckOutlined, CloseOutlined, DeleteOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Button, Input, Upload } from "antd";
import * as uploadHelper from "helpers/client/clientUploadHelper";
import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import ClientContext from "../../../../../context/ClientContext";
import {
  clientUpdateOperationHelper,
  toggleEditOperationHelper,
} from "../../../../../helpers/client/clientMissingDocHelper";
import openNotification from "../../../../../utils/notification";
import LostDocument from "./lostDocument";

function FileUpload(props) {
  const [isUploading, setIsUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [comment, setComment] = useState();
  const [isLost, setIsLost] = useState();
  const [isLostNoAccount, setIsLostNoAccount] = useState();

  const decodedToken = jwt_decode(props.tokenClient);
  const context = useContext(ClientContext);

  useEffect(() => {
    const filesToAdd = props.operation.files.map((file) => ({
      uid: file._id,
      _id: file._id,
      name: file.fileName,
      status: "done",
    }));
    setFileList(filesToAdd.filter((file) => !filesToRemove.map((f) => f.name).includes(file.name)));

    setComment(props.operation.comment);
    setIsLost(props.operation.isLost);
    setIsLostNoAccount(props.operation.isLostNoAccount);
  }, [props.operation]);

  const handleRemove = (file) => {
    setFileList((previous) => {
      const test = previous.filter((f) => f.name !== file.name);
      return test;
    });
    setFilesToRemove((previous) => {
      if (previous.map((f) => f.name).includes(file.name)) return previous;
      return [...previous, file];
    });
  };

  const handleBeforeUpload = (file) => {
    if (!uploadHelper.fileSizeIsOkHelper(file, props.fileSettings.fileSize)) return false;
    if (!uploadHelper.fileFormatIsOkHelper(file, props.fileSettings.fileTypes)) return false;
    if (!uploadHelper.fileNameIsOkHelper(file, props.operation, fileList)) return false;

    setFileList([...fileList, file]);
    return false;
  };

  const handleUpload = async () => {
    setIsUploading(true);

    await clientUpdateOperationHelper(
      context.idClient,
      props.operation,
      props.missingDoc?.title,
      comment,
      isLost,
      isLostNoAccount
    );

    let isSuccess = true;

    const filteredFilesToRemove = filesToRemove.filter((file) =>
      props.operation.files.map((f) => f.fileName).includes(file.name)
    );
    const deleteRes = await uploadHelper.handleOnRemoveHelper(
      filteredFilesToRemove,
      props.operation,
      context.idClient,
      decodedToken
    );
    isSuccess = deleteRes.every((r) => r.status === 200);
    if (!isSuccess) {
      openNotification(
        "error",
        "Erreur lors de la suppression d'un fichier. Veuillez recharger la page."
      );
      setIsUploading(false);
      return false;
    }

    const operationFiles = props.operation.files.map((file) => file.fileName);
    const fileToUpload = fileList
      .filter((file) => !operationFiles.includes(file.name))
      .filter((file) => !filesToRemove.map((f) => f.name).includes(file.name));
    const res = [];

    for (const file of fileToUpload) {
      const response = await uploadHelper.handleUploadHelper(
        file,
        props.operation,
        props.missingDoc,
        decodedToken,
        false
      );
      res.push(response);
    }
    isSuccess = res.every((r) => r.status === 200);
    if (isSuccess) {
      openNotification("success", "Action exécutée avec succès.");
      toggleEditOperationHelper(props.operation, props.setOperationsEditModeList);

      let listOfNewFiles = [];

      if (res.length > 0) {
        for (const file of res[res.length - 1].data.files) {
          if (props.operation.files.map((f) => f.fileName).includes(file.fileName)) continue;
          listOfNewFiles.push(file);
        }
      } else {
        listOfNewFiles.push(...props.operation.files);
      }

      const listOfFilesNameToRemove = filteredFilesToRemove.map((f) => f.name);
      listOfNewFiles = listOfNewFiles.filter(
        (file) => !listOfFilesNameToRemove.includes(file.fileName)
      );
      uploadHelper.updateStatesHelper(
        listOfNewFiles,
        props.setData,
        props.operation,
        comment,
        isLost,
        isLostNoAccount
      );
      return true;
    }
    res.forEach((r) => openNotification("error", r.message));
    setIsUploading(false);
    return false;
  };

  const handleDownload = async (file) => {
    await uploadHelper.downloadHelper(context.idClient, props.operation._id, file);
  };

  return (
    <>
      <Upload beforeUpload={(file) => handleBeforeUpload(file)} showUploadList={false}>
        <Button type="primary">Charger le(s) justificatif(s)</Button>
      </Upload>
      <div>
        {fileList.length > 0 &&
          fileList.map((file) => (
            <div className="ant-design-client-upload-reponsive">
              <span onClick={async () => await handleDownload(file)}>
                <PaperClipOutlined />
                <p>{file.name}</p>
              </span>
              <Button type="text" onClick={() => handleRemove(file)}>
                <DeleteOutlined />
              </Button>
            </div>
          ))}
      </div>
      <Input
        placeholder="Ajouter un commentaire"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />

      <div>
        {!props.isOtherType && (
          <LostDocument
            operation={props.operation}
            isLost={isLost}
            setIsLost={setIsLost}
            isLostNoAccount={isLostNoAccount}
            setIsLostNoAccount={setIsLostNoAccount}
            missingDocLostMessage={props.missingDocLostMessage}
          />
        )}
        <span>
          <Button
            type="default"
            icon={
              <CloseOutlined
                style={{
                  position: "relative",
                  top: "2px",
                }}
              />
            }
            onClick={() =>
              toggleEditOperationHelper(props.operation, props.setOperationsEditModeList)
            }
            shape="circle"
          />
          <Button
            type="default"
            icon={
              <CheckOutlined
                style={{
                  color: "hsla(137, 89%, 44%, 1)",
                  position: "relative",
                  top: "2px",
                }}
              />
            }
            shape="circle"
            loading={isUploading}
            onClick={async () => await handleUpload()}
          />
        </span>
      </div>
    </>
  );
}

export default FileUpload;
