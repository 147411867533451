import { ArrowRightOutlined, DownCircleOutlined, PaperClipOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ReactComponent as ImportantIcon } from "../../../../../assets/images/important.svg";
import {
  getSumHelper,
  toggleEditOperationHelper,
} from "../../../../../helpers/client/clientMissingDocHelper";
import FileUpload from "./upload";

const ClientMissingTablesResponsiveCard = (props) => {
  const [sum, setSum] = useState(0);
  const [operationsEditModeList, setOperationsEditModeList] = useState({});

  useEffect(() => {
    setSum(getSumHelper(props.data));

    const operationsEditModeListHasNoOperationInEditMode =
      Object.keys(operationsEditModeList).length === 0;
    if (operationsEditModeListHasNoOperationInEditMode) {
      let operationKeys = {};
      props.data.forEach((el) => {
        return el.operations.map((o) => {
          return (operationKeys[o._id] = false);
        });
      });
      setOperationsEditModeList(operationKeys);
    }
  }, [props.data]);

  return (
    <section className={`client-table-responsive`}>
      {props.data.length > 0 && (
        <>
          {!props.type && (
            <span>
              <h3>
                {props.label} - {parseFloat(sum).toFixed(2)} €
              </h3>
              <div></div>
            </span>
          )}

          {props.data.map((missingDoc, index) => {
            if (missingDoc.operations.length === 0 || missingDoc.amount === 0) return <></>;
            return (
              <div key={`missingDocMapping - ${index}`} className="missingDoc-operations">
                <div
                  onClick={() => {
                    props.setExpandedAccounts((previous) => ({
                      ...previous,
                      [missingDoc._id]: !previous[missingDoc._id],
                    }));
                  }}
                >
                  <span>
                    <ImportantIcon
                      className={`operation-title-circle ${
                        missingDoc.operations.every((op) => op.files.length > 0 || op.comment)
                          ? "operation-title-circle-is-validated"
                          : missingDoc.operations.every((op) => op.isLost || op.isLostNoAccount)
                          ? "operation-title-circle-is-lost"
                          : ""
                      }`}
                    />
                    <p>{missingDoc.title}</p>
                    <div></div>
                  </span>
                  <span>
                    <p>{parseFloat(Math.abs(missingDoc.amount)).toFixed(2)} €</p>
                    <DownCircleOutlined
                      className={
                        !props.expandedAccounts[missingDoc._id]
                          ? "expand-icon-down"
                          : "expand-icon-up"
                      }
                    />
                  </span>
                </div>
                <div
                  style={{ display: `${props.expandedAccounts[missingDoc._id] ? "flex" : "none"}` }}
                >
                  {props.expandedAccounts[missingDoc._id] &&
                    missingDoc.operations.map((op, index) => {
                      return (
                        <div
                          className="missingDoc-operation-responsive-card"
                          key={`operation-${index}`}
                        >
                          <div
                            onClick={() => toggleEditOperationHelper(op, setOperationsEditModeList)}
                          >
                            <span>
                              <ImportantIcon
                                style={
                                  (op.invalidFileComment && op.files.length === 0 && !op.comment) ||
                                  op.isLost ||
                                  op.isLostNoAccount
                                    ? {
                                        filter: "saturate(0%) brightness(94%) ",
                                      }
                                    : {}
                                }
                                className={`operation-title-circle ${
                                  op.files.length > 0 || op.comment
                                    ? "operation-title-circle-is-validated"
                                    : op.isLost || op.isLostNoAccount
                                    ? "operation-title-circle-is-lost"
                                    : ""
                                }`}
                              />
                              <span>
                                <p>{op.text}</p>
                                <p>
                                  {new Date(op.date).toLocaleDateString("fr-FR")} - {op.pieceRef} -{" "}
                                  {op.journal}
                                </p>
                              </span>
                            </span>
                            <span>
                              <p>{parseFloat(Math.abs(op.amount)).toFixed(2)} €</p>
                              <ArrowRightOutlined
                                style={{
                                  display: `${operationsEditModeList[op._id] ? "none" : "block"}`,
                                }}
                              />
                            </span>
                          </div>
                          <div>
                            {!operationsEditModeList[op._id] ? (
                              <>
                                <p style={{ display: `${op.isLostNoAccount ? "inline" : "none"}` }}>
                                  <b>Justificatif perdu non récupérable</b>
                                </p>
                                <p style={{ display: `${op.isLost ? "inline" : "none"}` }}>
                                  <b>Justificatif perdu récupérable</b>
                                </p>
                                <p style={{ display: `${op.commentCollab ? "inline" : "none"}` }}>
                                  <b>Commentaire du cabinet : </b>
                                  {op.commentCollab}
                                </p>
                                {op.comment && (
                                  <p>
                                    <b>Votre commentaire : </b>
                                    {op.comment}
                                  </p>
                                )}
                                {op.invalidFileComment &&
                                op.files.length === 0 &&
                                !op.comment &&
                                !op.isLost &&
                                !op.isLostNoAccount ? (
                                  <p style={{ color: "hsla(228, 93%, 62%, 1)" }}>
                                    <b>{op.invalidFileComment.split("***")[0]} - Invalidé : </b>
                                    {op.invalidFileComment.split("***")[1]}
                                  </p>
                                ) : (
                                  <></>
                                )}
                                {op.files.map((file, index) => (
                                  <span
                                    className="client-upload-file-list-not-edit-mode"
                                    key={`${index}`}
                                  >
                                    <PaperClipOutlined />
                                    <p>{file.fileName}</p>
                                  </span>
                                ))}
                              </>
                            ) : (
                              <>
                                <FileUpload
                                  setData={props.setMissingDocument}
                                  missingDoc={missingDoc}
                                  operation={op}
                                  maxFileSizeInMB={5}
                                  tokenClient={props.tokenClient}
                                  setOperationsEditModeList={setOperationsEditModeList}
                                  missingDocLostMessage={props.missingDocLostMessage}
                                  fileSettings={props.fileSettings}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </>
      )}
    </section>
  );
};

export default ClientMissingTablesResponsiveCard;
